import {makeStyles} from '@material-ui/styles';
import api from 'api-snej';
import ConfirmDialog from 'components/ConfirmDialog';
import {useSnackbar} from 'notistack';
import {useChannel} from 'push';
import React, {useEffect, useState} from 'react';
import {Table} from './components';
import HuntCreator from './components/Creator';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  table: {
    marginTop: '50px'
  }
}));

const HuntListPage = () => {
  const classes = useStyles();

  const [items, setItems] = useState([]);
  const [toBeDel, setToBeDel] = useState(null);
  const [itemDetails, setItemDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const {enqueueSnackbar} = useSnackbar();
  const [pagination, setPagination] = useState({
    count: 0,
    page: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 25, 50, 100]
  });

  const reload = () => {
    setIsLoading(true);
    // api
    //   .get(
    //     `/huntsets?page=${pagination.page + 1}&page_size=${
    //       pagination.rowsPerPage
    //     }`
    //   )
    //   .then(({ data }) => {
    //     setItems(data.results);
    //     setPagination({ ...pagination, count: data.count });
    //   })
    //   .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
    //   .finally(setIsLoading);
    if (localStorage.getItem('jens_username') === null
        || localStorage.getItem('jens_login_timestamp') === null
        || (new Date().getTime() - parseInt(localStorage.getItem('jens_login_timestamp')) > (24 * 3600 * 1000))) {
      window.location = '/sign-in';
      return;
    }
    let path = isAdmin() ? '/tasks/prices' : '/tasks/prices/' + btoa(localStorage.getItem('jens_username'));
    api
      .get(path)
      .then(({data}) => {
        setItems(data.tasks);
      })
      .catch((e) => enqueueSnackbar(e.message, {variant: 'error'}))
      .finally(setIsLoading);
  };
  const isAdmin = () => {
    return localStorage.getItem('jens_username') === 'admin'
  };
  const performDelete = () => {
    api
      .delete(`/tasks/prices/${toBeDel.tid}`)
      .then(() =>
        enqueueSnackbar(`${toBeDel.name} deleted`, {variant: 'info'})
      )
      .then(reload)
      .catch((e) => enqueueSnackbar(e.message, {variant: 'error'}))
      .finally(() => setToBeDel(null));
  };

  const incPriority = (item) => {
    api
      .post(`/tasks/prices/priority/inc/${item.tid}`)
      .then(reload)
      .catch((e) => enqueueSnackbar(e.message, {variant: 'error'}))
      .finally(() => setToBeDel(null));
  };

  const decPriority = (item) => {
    api
      .post(`/tasks/prices/priority/dec/${item.tid}`)
      .then(reload)
      .catch((e) => enqueueSnackbar(e.message, {variant: 'error'}))
      .finally(() => setToBeDel(null));
  };

  const onChangePage = (_, p) => setPagination({...pagination, page: p});
  const onChangeRowsPerPage = (p) =>
    setPagination({...pagination, rowsPerPage: p.target.value});

  useEffect(reload, [pagination.page, pagination.rowsPerPage]);

  useChannel('prices:task_update', ({task}) => {
    let found = false;
    const tt = [...items];
    for (let i = 0; i < tt.length; i++) {
      const o = tt[i];
      if (o.tid == task.tid) {
        tt[i] = task;
        found = true;
        break;
      }
    }
    if (!found && (isAdmin() || task.tag === btoa(localStorage.getItem('jens_username')))) {
      tt.push(task);
      tt.sort((a, b) => b.created_at - a.created_at);
    }
    setItems(tt);
  });

  return (
    <div className={classes.root}>
      <ConfirmDialog
        open={toBeDel != null}
        onCancel={() => setToBeDel(null)}
        onConfirm={performDelete}
        title="Delete Product Set?"
        text={`Are you sure you want to delete product set '${
          toBeDel ? toBeDel.name : ''
        }'?`}
      />
      <ConfirmDialog
        title="Sreatch Params"
        text={`Profit: '${itemDetails && itemDetails.profit ? itemDetails.profit : ''}'
        ${isAdmin() ? ', Filter: \'' + (itemDetails ? itemDetails.filter : '') + '\'' : ''}
        , URL: ${itemDetails ? itemDetails.query: ''}`}

        open={itemDetails != null}
        onCancel={() => setItemDetails(null)}
        onConfirm={() => navigator.clipboard.writeText(itemDetails.query + '')}
        yesText='Copy URL'
        noText='Close'
      />
      <div className={classes.content}>
        <HuntCreator onCreate={reload}/>
        <Table
          className={classes.table}
          items={items}
          isLoading={isLoading}
          deleteItem={setToBeDel}
          incPriority={incPriority}
          decPriority={decPriority}
          showDetails={setItemDetails}
          displayTag={isAdmin()}
          pagination={{...pagination, onChangePage, onChangeRowsPerPage}}
        />
      </div>
      {/* <Logs /> */}
    </div>
  );
};

export default HuntListPage;
