import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
  FieldList,
  FieldPage,
  HuntList,
  HuntPage,
  KeepaApiPage,
  NotFound as NotFoundView,
  ProductList as ProductListView,
  ProductPage,
  HomePage,
  ProductSetList,
  ProductSetPage,
  Settings as SettingsView,
  SheetsPage,
  SignIn as SignInView,
  TestCaseList,
  TestCasePage
} from './views';

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      <RouteWithLayout
        component={ProductSetList}
        exact
        layout={MainLayout}
        path="/productsets"
      />
      <RouteWithLayout
        component={ProductSetPage}
        layout={MainLayout}
        path="/productsets/:id"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={ProductPage}
        layout={MainLayout}
        path="/products/:id"
      />
      <RouteWithLayout
        component={FieldList}
        exact
        layout={MainLayout}
        path="/fields"
      />
      <RouteWithLayout
        component={FieldPage}
        layout={MainLayout}
        path="/fields/:id"
      />
      <RouteWithLayout
        component={TestCaseList}
        exact
        layout={MainLayout}
        path="/tests"
      />
      <RouteWithLayout
        component={TestCasePage}
        layout={MainLayout}
        path="/tests/:id"
      />
      <RouteWithLayout
        component={KeepaApiPage}
        layout={MainLayout}
        path="/keepa"
      />
      <RouteWithLayout
        component={KeepaApiPage}
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={HuntList}
        layout={MainLayout}
        exact
        path="/hunts"
      />
      <RouteWithLayout
        component={HuntPage}
        layout={MainLayout}
        path="/hunts/:id"
      />
      <RouteWithLayout
        component={SheetsPage}
        layout={MainLayout}
        path="/sheets"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={HomePage}
        exact
        layout={MainLayout}
        path="/home"
      />
      {/* <Redirect to="/not-found" /> */}
    </Switch>
  );
};

export default Routes;
