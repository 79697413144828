import { Button, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import api from 'api';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  productName: {
    fontSize: '18px',
    fontWeight: '400',
    marginLeft: '5px'
  },
  content: {
    marginTop: theme.spacing(2)
  },
  toolbar: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  button: {
    marginLeft: '10px'
  },
  loader: {
    marginRight: '20px'
  },
  recordCount: {
    marginTop: '20px',
    marginLeft: '40px'
  },
  recordCountLabel: {
    marginRight: '10px'
  },
  recordCountValue: {},
  recordTable: {
    maxHeight: 440
  }
}));

const KeepaApiPage = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [asin, setAsin] = useState();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    if (asin === '') {
      return;
    }
    setIsLoading(true);
    api
      .get(`/keepa/${asin}`)
      .then(({ data }) => {
        // console.log(typeof(data))
        // console.log(JSON.parse(data))
        setData(data);
      })
      .catch(e => enqueueSnackbar(e.message, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={classes.root}>
      {/*<div className={classes.toolbar}>*/}
      {/*  <span className={classes.spacer} />*/}
      {/*  {isLoading && <CircularProgress className={classes.loader} size={32} />}*/}
      {/*  <TextField*/}
      {/*    value={asin}*/}
      {/*    onChange={e => setAsin(e.target.value)}*/}
      {/*    placeholder="ASIN"*/}
      {/*  />*/}
      {/*  <Button*/}
      {/*    className={classes.button}*/}
      {/*    variant="contained"*/}
      {/*    onClick={onSubmit}>*/}
      {/*    Request Data*/}
      {/*  </Button>*/}
      {/*</div>*/}
      {/*<div className={classes.content}>/!* <Inspector data={ data } /> *!/</div>*/}
    </div>
  );
};

export default KeepaApiPage;
