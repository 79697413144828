import {Drawer} from '@material-ui/core';
import TestsIcon from '@material-ui/icons/DoneAll';
import KeepaIcon from '@material-ui/icons/Poll';
import HomeIcon from '@material-ui/icons/Home';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import RulesIcon from '@material-ui/icons/Tune';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import EmailIcon from '@material-ui/icons/Email';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import TokenStats from 'components/TokenStats';
import PropTypes from 'prop-types';
import React from 'react';
import {SidebarNav} from './components';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  supportContainer: {
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    color: 'rgba(0,0,0,0.5)'
  },
  supportTitle: {
    margin: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    fontSize: '14px'
  },
  supportItem: {
    margin: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    fontSize: '14px',
    cursor: 'pointer'
  },

  icon: {
    width: 14,
    height: 14,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    transform: 'scale(0.7)'
  },
  none: {
    color: 'white',
    width: 14,
    height: 14,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    transform: 'scale(0.7)'
  }
}));

const Sidebar = (props) => {
  const {open, variant, onClose, className, ...rest} = props;

  const classes = useStyles();

  const pages = [
    {
      limited: false,
      title: 'Home',
      href: '/Home',
      icon: <HomeIcon/>
    }, {
      limited: true,
      title: 'Products',
      href: '/productsets',
      icon: <ShoppingBasketIcon/>
    },
    {
      limited: true,
      title: 'Fields',
      href: '/fields',
      icon: <RulesIcon/>
    },
    {
      limited: true,
      title: 'Tests',
      href: '/tests',
      icon: <TestsIcon/>
    },
    {
      limited: true,
      title: 'Keepa',
      href: '/keepa',
      icon: <KeepaIcon/>
    },
    {
      limited: false,
      title: 'Search',
      href: '/hunts',
      icon: <SearchIcon/>
    },
    {
      limited: true,
      title: 'Sheets',
      href: '/sheets',
      icon: <ViewColumnIcon/>
    },
    {
      limited: true,
      title: 'Settings',
      href: '/settings',
      icon: <SettingsIcon/>
    },
    {
      limited: false,
      title: 'Account',
      url: 'https://oabeans.com/account-settings/?action=subscriptions',
      href: '/account',
      absoluteUrl: true,
      icon: <AccountBoxIcon/>
    }
  ];

  const isAdmin = () => {
    return localStorage.getItem('jens_username') === 'admin'
  };
  return (
    <Drawer
      anchor="left"
      classes={{paper: classes.drawer}}
      onClose={onClose}
      open={open}
      variant={variant}>
      {isAdmin() && <TokenStats className="p-8"></TokenStats>}
      <div {...rest} className={clsx(classes.root, className)}>
        {/* <Profile /> */}
        {/* <Divider className={classes.divider} /> */}
        <SidebarNav className={classes.nav}
                    pages={pages.filter(page => !page.limited || localStorage.getItem('jens_username') === 'admin')}/>
        {/* <UpgradePlan /> */}
      </div>
      <div className={classes.supportContainer}>
        <div className={classes.supportTitle}>
          <div className={classes.none}>
            <EmailIcon/>
          </div>
          <div>
            Support
          </div>
        </div>
        <div className={classes.supportItem} onClick={() => window.open('mailto:david.newby@oabeans.com', '_blank')}>
          <div className={classes.icon}>
            <EmailIcon/>
          </div>
          <div>
            Email Us
          </div>
        </div>
        <div className={classes.supportItem} onClick={() => window.open('https://wa.link/x3vwl0', '_blank')}>
          <div className={classes.icon}>
            <WhatsAppIcon/>
          </div>
          <div>
            Message Us on WhatsApp
          </div>
        </div>
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
