import {makeStyles} from '@material-ui/styles';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button} from "@material-ui/core";
import Img1 from "../../assets/1.jpg"
import Img2 from "../../assets/2.jpg"

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: '6em',
    paddingRight: '6em',
    paddingTop: '6em',
    textAlign: 'left',
    marginBottom: '1em',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    // border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: '1em',
    // boxShadow: '2px 2px 0 4px rgba(63,63,68,0.005)',
    // backgroundColor: 'rgba(255,255,255,1)'
  },
  mt150: {
    marginTop: '14em'
  },
  videoHint: {
    marginTop: '3em',
    marginBottom: '1em',
    fontSize: '1.5em',
  },
  videoContainer: {
    textAlign: 'center',
    marginTop: '4em',
    marginBottom: '3em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  header: {
    textAlign: 'center',
    fontSize: '3.5em',
    fontWeight: '700',
    marginTop: '2.5em',
    marginBottom: '1.5em'
  },
  title: {
    fontSize: '2.5em',
    fontWeight: '500'
  },
  cnt: {
    marginLeft: '2em',
    textAlign: 'justify',
    marginRight: '1em',
    fontSize: '1.3em',
    marginTop: '2.5em'
  },
  btn: {
    margin: '1em',
    minWidth: '13em',
    fontSize: '1em',
  },
  center: {
    fontSize: '1.5em',
    textAlign: 'center'
  },
  root: {
    backgroundColor: 'rgba(255,255,255,1)'
  },
  productName: {
    fontSize: '18px',
    fontWeight: '400',
    marginLeft: '5px'
  },
  content: {
    textAlign: 'center',
    marginTop: theme.spacing(2)
  },
  toolbar: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  button: {
    marginLeft: '10px'
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  loader: {
    position: 'fixed',
    bottom: 40,
    right: 40
  },
  recordCount: {
    marginTop: '20px',
    marginLeft: '40px'
  },
  recordCountLabel: {
    marginRight: '10px'
  },
  recordCountValue: {},
  recordTable: {
    maxHeight: 440
  }
}));

const ProductPage = () => {
  const classes = useStyles();
  const {id} = useParams();
  const [product, setProduct] = useState({});
  const downloadPDF = () => {
    const pdfUrl = "Why Drop Shipping Shines.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "Why Drop Shipping Shines.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="home-container">
      <div className={classes.root}>
        <div className={classes.toolbar}>
          <span className={classes.productName}>{product.title}</span>
          <span className={classes.spacer}/>
          <span className={classes.productName}>{product.asin}</span>
        </div>
        <div className={classes.content}>
          <div className={classes.container}>
            <div className={classes.header}>
              Amazon Cross-Border Arbitrage Software
            </div>
            <div className={classes.center}>
              Welcome! You’re now ready to explore profitable Amazon cross-border arbitrage with our software.
            </div>
            <div className={classes.center}>
              <Button className={classes.btn} color="primary"
                      variant="contained"
                      onClick={() => window.open("https://keepa.com/#!finder", "_blank")}>
                Go to Keepa Product Finder
              </Button>
              <Button className={classes.btn} color="primary"
                      variant="contained"
                      onClick={() => window.location.href = "/hunts"}>
                Start Your Search
              </Button>
            </div>
            <div className={classes.mt150}>
              Here’s everything you need to get started:
            </div>
            <div className={classes.title}>
              Step-by-Step Guide
            </div>
            <div>
              To help you make the most out of the software, follow these easy steps:
            </div>
          </div>

          <div className={classes.container}>
            <div className={classes.title}>
              Step 1: Understand the Business Model
            </div>
            <div className={classes.cnt}>
              Gain insights into the key strategies behind using our software, including why drop shipping is effective
              and
              how cross-border sourcing works.
            </div>
            <div className={classes.imageContainer}>
              <img src={Img1}/>
            </div>
            <div className={classes.center}>
              <Button className={classes.btn} color="primary"
                      variant="contained"
                      onClick={() => downloadPDF()}>
                Read the Guide (PDF)
              </Button>
            </div>
          </div>


          <div className={classes.container}>
            <div className={classes.title}>
              Step 2: Start with Keepa Product Finder
            </div>
            <div className={classes.cnt}>
              The process begins by identifying profitable products using Keepa Product Finder. Use Keepa’s filters to
              select products that fit your criteria, and then bring those products into our software to find
              cross-market
              matches.
              <br/>
              Please note, you ill need a paid Keepa account to access and filter products.
            </div>
            <div className={classes.videoContainer} id="keepa">
              <div className={classes.videoHint}>
                Watch Keepa Product Finder Tutorial
              </div>
              <div className='large-video'>
                <iframe
                  width="853"
                  height="480"
                  src={`https://www.youtube.com/embed/b5KyNYbRlnM`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
              <div className='medium-video'>
                <iframe
                  width="350"
                  height="280"
                  src={`https://www.youtube.com/embed/b5KyNYbRlnM`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
              <div className='small-video'>
                <iframe
                  width="250"
                  height="180"
                  src={`https://www.youtube.com/embed/b5KyNYbRlnM`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            </div>
            <div className={classes.center}>
              <Button className={classes.btn} color="primary"
                      variant="contained"
                      onClick={() => window.open("https://keepa.com/#!finder", "_blank")}>
                Go to Keepa Product Finder
              </Button>
            </div>
          </div>


          <div className={classes.container}>
            <div className={classes.title}>
              Step 3: Run Your First Search
            </div>
            <div className={classes.cnt}>
              Once you’ve selected your products on Keepa, input them into our software to see profitable opportunities
              in
              other Amazon marketplaces. Watch the tutorial to understand how to set up and start your first search.
            </div>
            <div className={classes.videoContainer} id="search">
              <div className={classes.videoHint}>
                Watch Software Search Tutorial
              </div>
              <div className='large-video'>
                <iframe
                  width="853"
                  height="480"
                  frameBorder="0"
                  src={`https://www.youtube.com/embed/wnqKhV61oLU`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
              <div className='medium-video'>
                <iframe
                  width="350"
                  height="280"
                  frameBorder="0"
                  src={`https://www.youtube.com/embed/wnqKhV61oLU`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
              <div className='small-video'>
                <iframe
                  width="250"
                  height="180"
                  frameBorder="0"
                  src={`https://www.youtube.com/embed/wnqKhV61oLU`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                />
              </div>
            </div>
            <div className={classes.center}>
              <Button className={classes.btn} color="primary"
                      variant="contained"
                      onClick={() => window.open("/hunts", "_blank")}>
                Start Search
              </Button>
            </div>
          </div>


          <div className={classes.container}>
            <div className={classes.title}>
              Step 4: Analyze Your Results
            </div>
            <div className={classes.cnt}>
              After completing your search, view the results to assess profitability and identify potential listings.
              Click on each search name to explore detailed results.
            </div>
            <div className={classes.imageContainer}>
              <img src={Img2}/>
            </div>
          </div>


          <div className={classes.container}>
            <div className={classes.title}>
              Step 5: Repeat and Refine
            </div>
            <div className={classes.cnt}>
              Success in cross-border arbitrage is achieved by persistence and refinement. Continuously run new searches
              with varied parameters, adding a range of products over time. As your list of ASINs grows, you’ll start to
              see patterns and find top-selling items. Aim to accumulate hundreds or even thousands of ASINs to increase
              the chances of discovering high-performing products, which will become your primary profit drivers. Keep
              iterating and fine-tuning your search strategy to maximize your results over the long term.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
